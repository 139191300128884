import React from "react";

import "../../styles/main.scss";

import Header from "./Header";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import TrackingCodes from "../components/TrackingCodes";

/**
 * Basic Layout Component. Uses page Context to display Header, Main and Footer Components.
 * Implements Breadcrumb logic.
 */
const Layout404 = props => {
  const currentLanguage = props.context.currentLanguage;
  const homepagePath = props.context.langHomepagePath;
  const mainClassName = props.className;
  const menus = props.context.menus;
  const logo = props.context.logo;
  const footerLogo = props.context.footerLogo;
  const useContainer = props.useContainer;
  const showProgressbar = props.showProgressbar;
  const progressBarTarget = props.progressBarTarget;
  const signUpLink = props.context.signUpLink;
  const stringTranslations = props.context.stringTranslations;

  return (
    <>
      <TrackingCodes />
      <Header
        homepage={homepagePath}
        language={currentLanguage}
        logo={logo}
        menu={menus.headerMenu && menus.headerMenu.node.items}
        showProgressbar={showProgressbar}
        progressBarTarget={progressBarTarget}
        searchPlaceholder={stringTranslations["Search"]}
      />
      <main className={mainClassName}>
        {useContainer ? (
          <Container>{props.children}</Container>
        ) : (
          <>{props.children}</>
        )}
      </main>
      <Footer menu={menus.footerMenu} signUpLink={signUpLink} logo={footerLogo} stringTranslations={stringTranslations} />
    </>
  );
};

Layout404.defaultProps = {
  useContainer: true
};

export default Layout404;
