import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../layouts/Layout404";
import {Row, Col} from "react-bootstrap";

const FourZeroFourPage = ({ pageContext, data }) => {
  const homepagePath = pageContext.langHomepagePath;
  const image = data.signupImage.childImageSharp.fluid;

  return (
    <Layout context={pageContext} className="four-page">
      <Row className="align-items-center">
        <Col lg={{ offset: 1, span: 5}}>
          <h1 className="four-page__title">Ooups... <br /> page not found</h1>
          <Link to={homepagePath} className="four-page__link">
            <button className="btn btn-primary">Back to Home</button>
          </Link>
        </Col>
        <Col lg={5}>
          <img src={image.src} className="four-page__img" srcSet={image.srcSet} alt="404 Illustration"/>
        </Col>
      </Row>
    </Layout>
  );
};

export const query = graphql`
    query FourPage {
        signupImage: file(relativeDirectory: {eq: "illustrations"}, name: {eq: "404"}) {
            childImageSharp {
                fluid {
                    src
                    srcSet
                }
            }
        }
    }
`;

export default FourZeroFourPage;
